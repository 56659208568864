import { Card, Row, Col } from 'antd';
import React from 'react';
import ClienteDeudores from './ClienteDeudores';
import ProductoBusqueda from './ProductoBusqueda';

const Dashboard = () => {
  return (
    <div>
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <Card title={'Búsqueda de Producto'}>
            <ProductoBusqueda />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <Card title={'Deuda'} bodyStyle={{ padding: '5px', height: '350px' }}>
            <ClienteDeudores />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
